import React from "react"
import NavbarThree from "../components/App/NavbarThree"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import MainBanner from "../components/SolutionRecruitments/Sa1mainBanner"
import OurSolutions from "../components/SolutionRecruitments/OurSolutions"
import AboutUs from "../components/SolutionRecruitments/AboutUs"
import OurServices from "../components/SolutionRecruitments/OurServices"
import HowItWork from "../components/SolutionRecruitments/Sa2howItWork"
import CaseStudySlider from "../components/SolutionRecruitments/Sa5caseStudySlider"
import AlternateBanner from "../components/SolutionRecruitments/Sa3alternateBanner"

import TeamMembers from "../components/SolutionRecruitments/TeamMembers"
import Testimonials from "../components/SolutionRecruitments/Testimonials"
import Partner from "../components/SolutionRecruitments/Partner"
import BlogPost from "../components/SolutionRecruitments/Sa6blogPost"
import Form from "../components/SolutionRecruitments/Sa4form"

const Home = ({location}) => {
  return (
    <Layout path={location.pathname}>
      <NavbarThree />
      <MainBanner />

      <HowItWork />
      <AlternateBanner />
        <Form />

        <CaseStudySlider />
        <BlogPost />

      <Footer />
    </Layout>
  )
}

export default Home;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;